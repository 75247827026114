/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.settings').controller('UserDetailListController', ['$scope', '$state', '$stateParams', '$timeout', 'uiGridConstants', 'UserResourceFactory', function ($scope, $state, $stateParams, $timeout, uiGridConstants, UserResourceFactory) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this; // Store the search params in memory as we go

    var SearchParams = {};
    /* BINDABLE : DATA */

    vm.paginationOptions = {
      pageNumber: 1,
      pageSize: 25,
      sort: null
    };
    vm.currentId = null;
    vm.gridOptions = {
      paginationPageSizes: [25, 50, 75],
      paginationPageSize: 25,
      paginationCurrentPage: vm.paginationOptions.pageNumber,
      useExternalPagination: true,
      enableColumnMenus: false,
      enableRowSelection: true,
      enableSorting: false,
      enableRowHeaderSelection: false,
      modifierKeysToMultiSelect: true,
      multiSelect: true,
      appScopeProvider: vm,
      rowTemplate: '<div ng-class="{ \'current-detail-open\': row.entity.currentSelection }"><div ng-dblclick="grid.appScope.openDetail(row.entity)" mbl-dblclick="grid.appScope.openDetail(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div></div>',
      columnDefs: [{
        name: 'username',
        field: 'userName',
        displayName: 'email'
      }],
      onRegisterApi: function onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
          vm.paginationOptions.pageNumber = newPage;
          vm.paginationOptions.pageSize = pageSize;
          vm.getPage(SearchParams);
        });
      }
    };
    /* BINDABLE : METHODS */

    vm.getPage = function (params) {
      params = params !== undefined ? params : {};
      params.limit = vm.paginationOptions.pageSize;
      params.offset = vm.paginationOptions.pageSize * (vm.paginationOptions.pageNumber - 1);
      UserResourceFactory.getAll(params, function (users) {
        vm.gridOptions.totalItems = users.data.count;

        for (var i = 0; i < users.data.rows.length; i++) {
          if (users.data.rows[i].id * 1 === vm.currentId * 1) {
            users.data.rows[i].currentSelection = true;
          }
        }

        vm.gridOptions.data = users.data.rows;
      }, function errorHandler() {
        // Something went wrong, so display nothing
        vm.gridOptions.totalItems = 0;
        vm.gridOptions.data = [];
      });
    };

    vm.openDetail = function (row) {
      $state.go('userDetails', {
        id: row.id,
        pageNumber: vm.paginationOptions.pageNumber
      });
    };

    vm.backToSearch = backToSearch;
    /* EVENTS */
    //Register any event listeners

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      // Wait for the current digest cycle to finish, so the grid will display properly
      $timeout(function () {
        // Utilize parameters
        SearchParams = toParams;
        vm.currentId = toParams.id;
        vm.getPage(SearchParams);
      });
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function backToSearch() {
      $state.go('users', {
        pageNumber: vm.paginationOptions.pageNumber
      }, {
        notify: true
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      if ($stateParams.pageNumber && $stateParams.pageNumber !== 0) {
        vm.paginationOptions.pageNumber = parseInt($stateParams.pageNumber, 10);
        vm.gridOptions.paginationCurrentPage = vm.paginationOptions.pageNumber;
      }
    }
  }]);
})();