/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.settings').controller('UsersController', ['$scope', '$state', '$stateParams', '$timeout', '$window', 'addelivery.event.constants', 'uiGridConstants', 'UserResourceFactory', 'EndPointService', 'loginService', function ($scope, $state, $stateParams, $timeout, $window, EventConstants, uiGridConstants, UserResourceFactory, EndPointService, loginService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this; // Store the search params in memory as we go

    var SearchParams = {};
    /* BINDABLE : DATA */

    vm.paginationOptions = {
      pageNumber: 1,
      pageSize: 25,
      sortField: null,
      sortDirection: null
    };
    vm.gridOptions = {
      paginationPageSizes: [25, 50, 75],
      paginationPageSize: 25,
      paginationCurrentPage: vm.paginationOptions.pageNumber,
      useExternalPagination: true,
      useExternalSorting: true,
      enableColumnMenus: false,
      enableRowSelection: true,
      enableRowHeaderSelection: false,
      modifierKeysToMultiSelect: true,
      multiSelect: true,
      appScopeProvider: vm,
      rowTemplate: '<div mbl-dblclick="grid.appScope.openDetail(row.entity)" ng-dblclick="grid.appScope.openDetail(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div>',
      columnDefs: [{
        name: 'username',
        field: 'userName',
        displayName: 'email',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'name',
        field: 'fullName',
        displayName: 'name',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'title',
        field: 'title',
        displayName: 'title',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'enabled',
        field: 'enabled',
        displayName: 'enabled?',
        enableSorting: false,
        cellTemplate: "<div class=\"ui-grid-cell-contents\"><i ng-class=\"['far', {'fa-check-square': row.entity.enabled, 'fa-square': !row.entity.enabled}]\"></i></div>",
        headerCellTemplate: require('../customHeader-template.html')
      }],
      onRegisterApi: function onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
          if (sortColumns.length == 0) {
            vm.paginationOptions.sort = null;
          } else {
            vm.paginationOptions.sortField = sortColumns[0].field;
            vm.paginationOptions.sortDirection = sortColumns[0].sort.direction.toUpperCase();
          }

          vm.getPage(SearchParams);
        });
        gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
          vm.paginationOptions.pageNumber = newPage;
          vm.paginationOptions.pageSize = pageSize;
          vm.getPage(SearchParams);
        });
      }
    };
    /* BINDABLE : METHODS */

    vm.newUser = newUser;
    vm.exportToCsv = exportToCsv;

    vm.getPage = function (params) {
      params = params !== undefined ? params : {};
      params.limit = vm.paginationOptions.pageSize;
      params.offset = vm.paginationOptions.pageSize * (vm.paginationOptions.pageNumber - 1);
      params.sortField = vm.paginationOptions.sortField;
      params.sortDirection = vm.paginationOptions.sortDirection;
      UserResourceFactory.getAll(params, function (users) {
        vm.gridOptions.totalItems = users.data.count;
        vm.gridOptions.data = users.data.rows;
        vm.exportLink = users.data.exportUrl;
      }, function errorHandler() {
        // Something went wrong, so display nothing
        vm.gridOptions.totalItems = 0;
        vm.gridOptions.data = [];
      });
    };

    vm.openDetail = function (row) {
      angular.element(document.querySelector('.sidebar')).addClass('invisible');
      $state.go('userDetails', {
        id: row.id,
        pageNumber: vm.paginationOptions.pageNumber
      }).then(function () {
        // Apply the animation ONLY when this transition happens
        $timeout(function () {
          angular.element(document.querySelector('.sidebar')).removeClass('invisible');
          angular.element(document.querySelector('.user-detail-list-area .user-grid')).addClass('slide-left-transition');
        }, 0);
      });
    };
    /* EVENTS */
    //Register any event listeners


    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      // Wait for the current digest cycle to finish, so the grid will display properly
      $timeout(function () {
        // Utilize parameters
        SearchParams = toParams;
        vm.getPage(SearchParams);
      });
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function newUser() {
      $state.go('userDetails', {
        id: 'new'
      });
    }

    function exportToCsv() {
      // Point to the export endpoint
      var endpoint = EndPointService.userEndPoint + '/export?authorization=' + loginService.getJwt();
      $window.open(endpoint);
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      if ($stateParams.pageNumber && $stateParams.pageNumber !== 0) {
        vm.paginationOptions.pageNumber = parseInt($stateParams.pageNumber, 10);
        vm.gridOptions.paginationCurrentPage = vm.paginationOptions.pageNumber;
      }
    }
  }]);
})();