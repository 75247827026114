/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.settings', 'ngMaterial', 'ngMessages'];
var defaultName = angular.module('comcast.settings.users', dependencies).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('users', {
    // Specify params that this state can access from the URL
    url: '/users?userEmail&userName&{includeArchives:bool}&userJob&callLetter&pageNumber',
    parent: 'settings',
    // Specify default values for state params
    params: {
      userJob: null,
      userEmail: null,
      userName: null,
      includeArchives: false,
      pageNumber: null,
      callLetter: null
    },
    reloadOnSearch: true,
    views: {
      'settingsContent@settings': {
        template: '<ui-view name="usersContent"/>'
      },
      'settingsSearch@settings': {
        template: '<ui-view name="usersSearch"/>'
      },
      'usersContent@users': {
        template: require('./users-template.html'),
        controller: 'UsersController',
        controllerAs: 'vm'
      },
      'usersSearch@users': {
        template: require('./usersSearch-template.html'),
        controller: 'UserSearchController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('userDetails', {
    url: '/:id',
    parent: 'users',
    params: {
      gridData: null,
      pageData: null
    },
    views: {
      usersContent: {
        template: require('./userdetail-template.html'),
        controller: 'UserDetailController',
        controllerAs: 'vm'
      },
      usersSearch: {
        template: require('./userDetailList-template.html'),
        controller: 'UserDetailListController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };